import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
          Our web development gallery typically refers to a collection of websites or web applications that showcase the skills and capabilities of our web developers and designers. These galleries serve as portfolios, examples, or inspiration for our clients looking to create unique, effective, and visually appealing websites.
          </p> 
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    
                        <Image
                          site_url={d.site_url}
                          title={d.title}
                          description={d.description}
                          largeImage={d.largeImage}
                          smallImage={d.smallImage}
                        />
                  </div>
                 
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
